import React, { Children } from 'react'
import { MDBIcon, MDBRow, MDBCol } from 'mdbreact';

const IconBullet = ( props ) => {
    return (
        <MDBRow className="py-1">
            <MDBCol size="2">
            <MDBIcon far={props.far} fab={props.fab} icon={props.icon} size="2x" className="cyan-text pr-3"/>
            </MDBCol>
            <MDBCol size="10">
            <p>{props.children}</p>
            
            </MDBCol>
        </MDBRow>
    )
}

export default IconBullet