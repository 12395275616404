import React from "react";
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBContainer, MDBRow, MDBCol, MDBView, MDBMask } from
    "mdbreact";
import image4 from "../images/abstract-art1.webp"
import image2 from "../images/abstract-art2.webp"
import image3 from "../images/abstract-art3.webp"

const CarouselPage = () => {
    return (
        <MDBRow>
            <MDBCol>
        <MDBCarousel activeItem={1} length={6} className="z-depth-1 w-100">
            <MDBCarouselInner>
                <MDBCarouselItem itemId="1">
                    <MDBView>
                        <img className="d-block w-100" src="https://www.audiobuzz.org/images/headshot.jpg?width=640" alt="First slide" />
                        <MDBMask overlay="white-strong" />
                    </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="2">
                    <MDBView>
                        <img className="d-block w-100" src="https://www.audiobuzz.org/images/Amanda%20Expo%20photos%204.jpg?width=640" alt="Second slide" />
                        <MDBMask overlay="white-strong" />
                    </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="3">
                    <MDBView>
                        <img className="d-block w-100" src="https://www.audiobuzz.org/images/Amanda%20Expo%20photos%2015.jpg?width=640" alt="Third slide" />
                        <MDBMask overlay="white-strong" />
                    </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="4">
                    <MDBView>
                        <img className="d-block w-100" src="https://www.audiobuzz.org/images/Amanda%20Expo%20photos%2032.jpg?width=640" alt="Fourth slide" />
                        <MDBMask overlay="white-strong" />
                    </MDBView>
                </MDBCarouselItem>
                <MDBCarouselItem itemId="5">
                    <MDBView>
                        <img className="d-block w-100" src="https://www.audiobuzz.org/images/Amanda%20Expo%20photos%202.jpg?width=640" alt="Fifth slide" />
                        <MDBMask overlay="white-strong" />
                    </MDBView>
                </MDBCarouselItem>

                <MDBCarouselItem itemId="6">
                    <MDBView>
                        <img className="d-block w-100" src="https://www.audiobuzz.org/images/Amanda%20Expo%20photos%2010.jpg?width=640" alt="Mattonit's item" />
                        <MDBMask overlay="white-strong" />
                    </MDBView>
                </MDBCarouselItem>
            </MDBCarouselInner>
        </MDBCarousel>
        </MDBCol>
        </MDBRow>
    );
}

export default CarouselPage;
