import React, { Component } from "react";
import {
  MDBContainer, MDBNavbar,  MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBFormInline,
  MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBCol, MDBRow
} from "mdbreact";
import { Link } from 'gatsby';
import CustomNavLink from './customLink';
import { ReactComponent as Logo } from "../images/light-bulb.svg";

class NavbarPage extends Component {
  state = {
    isOpen: false
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    return (
      

      <MDBContainer className="pb-0">
        <MDBRow>
          <MDBCol size="7" md="3" className="offset-xl-0 mx-auto mt-2">
            <img className="img-fluid" src="https://amanda.net.au/static/img/logo-caption.png" alt="" />
          </MDBCol>
        </MDBRow>
      <MDBNavbar light expand="md" className="navigation-clean mt-2 z-depth-0">
      <MDBNavbarToggler onClick={this.toggleCollapse} />
      <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
      <MDBNavbarNav>
        <CustomNavLink to="/">Home</CustomNavLink>
        <MDBDropdown>
          <MDBDropdownToggle nav caret>
            <span className="mr-2">My Services</span>
          </MDBDropdownToggle>
          <MDBDropdownMenu>
            <MDBDropdownItem href="/marriage/">Marriage Services</MDBDropdownItem>
            <MDBDropdownItem href="/vow-renewal/">Renewal of Vows</MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
        <CustomNavLink to="/why-me/">Why Me</CustomNavLink>
        <CustomNavLink to="/about/">About Me</CustomNavLink>
        <CustomNavLink to="/testimonials/">Testimonials</CustomNavLink>
        <CustomNavLink to="/faq/">FAQ</CustomNavLink>
        <CustomNavLink to="/contact/">Contact</CustomNavLink>
        <CustomNavLink to="/book-me/">Book Me</CustomNavLink>
      </MDBNavbarNav>
      </MDBCollapse>
      </MDBNavbar>
      <hr className="mb-0"/>
      </MDBContainer>
    );
  }
}

export default NavbarPage;
